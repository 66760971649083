/* ----- Selectors by element ----- */

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
  text-align: center;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: x-large;
  text-align: center;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: large;
  text-align: center;
}

/* ----- Selectors by class ----- */

.App-header {
  font-size: calc(10px + 2vmin);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #E60032;
}

/* ----- Selectors by id ----- */
#impressum {
  color: #ffffff;
}

#impressum a:link, #impressum a:visited, #impressum a:active {
  color: #ffffff;
}

/* -----print-view specific css ----- */
@media print{
  h1 {
    font-size: large;
  }

  h2 {
    font-size: medium;
  }
}